<template>
  <div>
    <v-row class="mx-0 mt-n2 mb-1 px-6 px-md-0">
      <v-col class="sudoku-stars text-center pa-0 mb-print-0">
        <v-btn
          v-for="index in 5"
          :key="index"
          :href="getHrefByLevel(index)"
          :aria-label="getPurposeByLevel(index)"
          class="text-center ml-n1 mr-n2"
          small
          text
          fab
        >
          <fa-layers>
            <fa-icon
              size="2x"
              :class="$theme.sudoku.stars.color1[getState(index)]"
              :icon="['fas', 'star']"
              transform="shrink-1 left-5"
            ></fa-icon>
            <fa-icon
              size="2x"
              :class="$theme.sudoku.stars.color2[getState(index)]"
              :icon="['fal', 'star']"
              transform="shrink-1 left-5"
            ></fa-icon>
          </fa-layers>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { theme } from '@/mixins'

export default {
  name: 'SudokuStars',
  mixins: [theme],
  computed: {
    sudoku() {
      return this.$store.getters.sudoku
    },
  },
  methods: {
    getHrefByLevel(level) {
      return this.$t(`hyperlinks.level_${level}.href`)
    },
    getPurposeByLevel(level) {
      return this.$t(`hyperlinks.level_${level}.purpose`)
    },
    getState(number) {
      return number <= this.sudoku.stars ? 'active' : 'inactive'
    },
    getPurpose(level) {
      return this.$t(`hyperlinks.level_${level}.purpose`)
    },
  },
}
</script>

<style lang="scss">
.sudoku-stars {
  position: relative;
  z-index: 2;
  margin-bottom: 32px;
}
@media print {
  .mb-print-0 {
    margin-bottom: 0 !important;
  }
}
</style>
