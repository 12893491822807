<template>
  <div>
    <h1 data-test-id="home-title" class="d-print-none hidden-xs-only">{{ $t('globals.sudoku') }}</h1>
    <p class="d-print-none hidden-sm-and-down">{{ $t('views.home.p_info') }}</p>
    <p class="d-print-none hidden-xs-only mb-sm-12">{{ sudoku.stars !== null ? $t(`views.home.p_level_${sudoku.stars}`) : '' }}</p>
    <div>
      <h2 class="text-center pl-6 home--h2-title">
        <i18n v-if="sudoku.id !== null" path="views.home.sudoku_number" class="cursor-pointer" @click="dialogEdit = true">
          <template #SudokuNumber>
            {{ formatNumber(sudoku.id.toString()) }}
            <fa-icon :icon="['fad', 'pencil']" size="xs" class="ml-1 d-print-none" />
          </template>
        </i18n>
      </h2>
      <v-divider class="d-print-none mx-6 mx-sm-0 mb-3"></v-divider>
      <SudokuStars />
      <Sudoku />
      <div class="d-print-none">
        <div class="text-center pt-3 mx-auto mb-3" style="width: calc(100vw - 48px); max-width: 400px">
          <v-btn v-if="!isSolved" outlined rounded color="$theme.btn.color" @click="checkSudoku()">
            <fa-icon class="mr-2" size="lg" :icon="['fad', 'tasks']" />{{ $t('views.home.btn_check_sudoku') }}
          </v-btn>
          <btn v-else :aria-label="$t('views.home.btn_new_puzzle.aria_label')" @click.prevent="reload()">{{
            $t('views.home.btn_new_puzzle.text')
          }}</btn>
        </div>
      </div>
    </div>
    <div class="d-print-none">
      <v-dialog v-model="dialogEdit" persistent max-width="520">
        <form novalidate @submit.prevent="submit()">
          <v-card>
            <v-card-title class="headline">
              <div class="mx-auto">Sudoku auswählen</div>
            </v-card-title>
            <v-card-text class="text-center">
              <p>Bitte gib die Nummer des Sudoku Rätsels ein und klicke dann auf "Weiter".</p>
              <v-row class="my-0">
                <v-col offset="3" offset-sm="4" cols="6" sm="4" class="text-right">
                  <v-text-field
                    v-model="number"
                    :disabled="disableFormElements"
                    :error-messages="numberErrors"
                    :label="$t('views.home.input_number')"
                    color="teal"
                    autofocus
                    clearable
                    @input="numberInput()"
                    @blur="numberInput()"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="teal" text type="submit">{{ $t('globals.btn_continue') }}</v-btn>
              <v-btn color="grey" text @click="dialogEdit = false">{{ $t('globals.btn_cancel') }}</v-btn>
            </v-card-actions>
          </v-card>
        </form>
      </v-dialog>
      <v-dialog v-model="dialogCongratulations" persistent max-width="520">
        <v-card>
          <v-card-title class="headline pt-6 text-center" style="display: block">
            {{ $t('views.home.headline_congratulations') }}

            <v-btn fab text style="position: absolute; top: 10px; right: 8px" @click="dialogCongratulations = false">
              <fa-icon :class="$theme.app.navigation.buttons.open.text" size="2xl" :icon="['fal', 'times']"></fa-icon>
            </v-btn>
          </v-card-title>
          <v-card-text class="text-center">
            <div class="pt-3 mb-3">
              <fa-icon class="amber--text text--accent-2" size="5x" :icon="['fad', 'trophy-alt']" />
            </div>
            <p>{{ $t('views.home.p_solved') }}</p>
            <div v-if="!isSupporter && $vuetify.breakpoint.mdAndUp">
              <Adslot ad-unit="solved-rectangle" ad-class="adslot__modal-solved" class="mx-auto" />
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
      <div class="text-center mb-3">
        <Adslot ad-unit="content-first" ad-class="adslot__content-first" />
      </div>
      <h2>{{ $t('views.home.h2_sudoku_online') }}</h2>
      <p>{{ $t('views.home.p_sudoku_online') }}</p>

      <h2>{{ $t('views.home.h3_about_sudoku') }}</h2>
      <p>{{ $t('views.home.p_about_sudoku_1') }}</p>
      <ul class="mb-4">
        <li>{{ $t('views.home.li_about_sudoku_1') }}</li>
        <li>{{ $t('views.home.li_about_sudoku_2') }}</li>
        <li>{{ $t('views.home.li_about_sudoku_3') }}</li>
      </ul>
      <p>{{ $t('views.home.p_about_sudoku_2') }}</p>
      <p>{{ $t('views.home.p_about_sudoku_3') }}</p>
      <h2>{{ $t('views.instructions.h1_title') }}</h2>
      <p>{{ $t('views.instructions.p_instructions_1') }}</p>
      <p>
        <hyperlink :href="$t('hyperlinks.instructions.href')" :aria-label="$t('hyperlinks.instructions.purpose')">{{
          $t('globals.show_more')
        }}</hyperlink>
      </p>
      <div class="text-center my-3">
        <Adslot ad-unit="content-last" ad-class="adslot__content-last" />
      </div>
    </div>
  </div>
</template>

<script>
import Adslot from '@/components/Adslot.vue'
import Btn from '@/components/Btn.vue'
import Hyperlink from '@/components/Hyperlink.vue'
import Sudoku from '@/components/Sudoku'
import SudokuStars from '@/components/sudoku/Stars.vue'
import { Com } from '@/util'
import { mapActions, mapGetters } from 'vuex'
import { required, minValue, maxValue, integer } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'
import { puzzles } from '@/config/index.js'
import { theme } from '@/mixins'

export default {
  name: 'Home',
  components: { Adslot, Btn, Hyperlink, Sudoku, SudokuStars },
  mixins: [validationMixin, theme],
  data() {
    return {
      dialogCongratulations: false,
      dialogEdit: false,
      disableFormElements: false,
      number: '',
      overlay: false,
      levels: {
        [this.$t('hyperlinks.level_1.href')]: 1,
        [this.$t('hyperlinks.level_2.href')]: 2,
        [this.$t('hyperlinks.level_3.href')]: 3,
        [this.$t('hyperlinks.level_4.href')]: 4,
        [this.$t('hyperlinks.level_5.href')]: 5,
      },
    }
  },
  validations: {
    number: {
      integer,
      maxValue: maxValue(puzzles.maxValue),
      minValue: minValue(puzzles.minValue),
      required,
    },
  },
  computed: {
    ...mapGetters(['user', 'profile', 'progress', 'isSupporter']),
    isAuthenticated() {
      return !!this.user.authentication.exp
    },
    id() {
      if (typeof this.$route.query.id === 'string') {
        return parseInt(this.$route.query.id)
      }
      return false
    },
    puzzles() {
      return puzzles
    },
    sudoku() {
      return this.$store.getters.sudoku
    },
    isSolved() {
      return this.sudoku.solved
    },
    numberErrors() {
      const errors = []
      if (!this.$v.number.$dirty) return errors
      !this.$v.number.required && errors.push(this.$t('vuelidate.error.required'))
      !this.$v.number.integer && errors.push(this.$t('vuelidate.error.integer'))
      !this.$v.number.minValue && errors.push(this.$t('vuelidate.error.minValue'))
      !this.$v.number.maxValue && errors.push(this.$t('vuelidate.error.maxValue'))
      return errors
    },
  },
  async created() {
    const com = new Com()
    if (!this.endpointHasLevel() && this.id) {
      const res = await com.getSudokuById(this.id)
      if (typeof res === 'object' && res.data.savegame) {
        await this.$store.dispatch('setSudoku', JSON.parse(res.data.savegame))
      } else {
        await this.$store.dispatch('initSudoku', res.data.sudoku)
      }
    } else if (!this.endpointHasLevel() && this.isAuthenticated) {
      const res = await com.getRecentSavegame()
      if (typeof res === 'object' && res.data.savegame) {
        await this.$store.dispatch('setSudoku', JSON.parse(res.data.savegame))
      } else {
        const res = await com.getSudokuByLevel(this.level())
        await this.$store.dispatch('initSudoku', res.data.sudoku)
      }
    } else {
      const res = await com.getSudokuByLevel(this.level())
      await this.$store.dispatch('initSudoku', res.data.sudoku)
    }
    if (!this.endpointHasLevel()) {
      await this.$router.push({ path: String(this.$t(`hyperlinks.level_${this.sudoku.stars}.href`)) })
    }
  },
  methods: {
    ...mapActions(['setPurpose', 'showLoader', 'setSavingActive']),
    endpointHasLevel() {
      return typeof this.levels[this.$route.path] !== 'undefined'
    },
    level() {
      return this.endpointHasLevel() ? this.levels[this.$route.path] : 3
    },
    async submit() {
      this.disableFormElements = true
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.gotoSudoku(this.number)
      }
      this.disableFormElements = false
    },
    async checkSudoku() {
      const solution = this.sudoku.solution.split('')
      let total = this.sudoku.puzzle.split('')
      await this.$store.dispatch('setActiveCellId', null)
      this.sudoku.entries.forEach((entry, index) => {
        if (entry === null) return
        if (typeof entry !== 'undefined' && Number(entry) !== Number(solution[index])) {
          this.$store.dispatch('setWarning', {
            elementId: index,
            val: true,
          })
        }
        total[index] = String(entry)
      })
      await this.$store.dispatch('incrementChecked')

      if (this.isAuthenticated) {
        this.setSavingActive(true)
        if (total.toString() === solution.toString()) await this.$store.dispatch('setSolved')
        const com = new Com()
        const response = await com.updateSavegame(this.sudoku.id, JSON.stringify(this.sudoku), this.progress)
        if (typeof response === 'object') {
          await this.$store.dispatch('resetSavingCounter')
          if (this.sudoku.solved) {
            this.dialogCongratulations = true
            const sudokuStatistics = await com.getSudokuStatistics()
            await this.$store.dispatch('setSudokuStatistics', sudokuStatistics.data)
          }
        }
        setTimeout(() => {
          this.setSavingActive(false)
        }, 1)
      } else {
        if (total.toString() === solution.toString()) await this.$store.dispatch('setSolved')
        if (this.sudoku.solved) {
          this.dialogCongratulations = true
        }
      }
    },
    numberInput() {
      this.$v.number.$reset()
    },
    gotoSudoku(number) {
      this.setPurpose(this.$t('hyperlinks.id.purpose', { number: this.formatNumber(number) }))
      this.dialogEdit = false
      this.showLoader()
      setTimeout(() => {
        location.href = String(this.$t('hyperlinks.id.href', { number }))
      }, 1)
    },
    reload() {
      this.setPurpose(String(this.$t('views.home.btn_new_puzzle.aria_label')))
      this.showLoader()
      setTimeout(() => {
        location.href = this.$router.currentRoute.path
        self.hideLoader({})
      }, 1)
    },
    formatNumber(number) {
      return new Intl.NumberFormat(this.$i18n.locale).format(number)
    },
  },
}
</script>

<style lang="scss">
@media print {
  .home {
    &--h2-title {
      margin-left: -24px;
    }
  }
}
</style>
